<template>
    <DataTable :value="packList" :paginator="packList.length>10?true:false" class="p-datatable-sm mt-3" v-model:expandedRows="expandedItems" dataKey="packId" stripedRows  :rows="10">          
        <Column  style="width:1rem">
            <template #body="{data}">
                <RadioButton :value="data.packId" v-model="selectedPackId" />
            </template>
        </Column>
        <Column :expander="true" style="width:0.1rem" />
            <Column field="packName" header="装箱单名称">
                <template #body="{data}">
                    {{ data.packName }}
                    <Tag v-if="data.error" class="ml-2" icon="pi pi-times" severity="danger" value="此装箱单有商品条码不属于本店铺"></Tag>
                </template>
            </Column>
            <Column header="箱子总数">
            <template #body="{data}">
                {{ data.boxs.length }}
            </template></Column>
            <Column field="createTime" header="创建时间"></Column>
            
            <template #expansion="{data}">
            <DataTable :value="data.products" class="p-datatable-sm mt-3" stripedRows  :rows="20">
                        <Column field="fnsku" header="商品信息">
                            <template #body="{data}">
                                <itemInfo  :item="{imgUrl:translate[data.fnsku].imgUrl,asin:'1',fnsku:'1',sku:'1'}">
                                    <template #asin>
                                        商品名称：{{translate[data.fnsku].productNameZh}}
                                    </template>
                                    <template #sku>
                                        ASIN：{{translate[data.fnsku].asin}}
                                    </template>
                                    <template #fnsku>
                                        FNSKU：{{data.fnsku}}
                                        <Tag v-if="data.error" class="ml-2" icon="pi pi-times" severity="danger" value="此商品条码不属于本店铺"></Tag>
                                    </template>
                                </itemInfo>
                            </template>
                        </Column>
                        <Column field="qty" header="数量"></Column>
                    </DataTable>
                </template>
                <template #footer>
                    <div style="justify-content:space-between;display: flex;">
                        <Button :disabled="btnNextDisable" label="下一步" @click="createShipmentPlan" />
                        <Button icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="()=>{this.getPackList();this.selectedPack={products:[]};this.selectedPackId=null;}" />
                        </div>
                </template>
    </DataTable>        
</template>

<script>
import Service from '../../../service/Service';
import itemInfo from '../../common/itemInfo.vue';


export default {
    emits:['createShipmentPlan'],
    props: {
        items:Array
    },
    data() {
        return {
            expandedItems:null,
            packList:[],
            selectedPackId:null,
            selectedPack:{products:[]},
            btnNextDisable:true,
            translate:{}
        }
    },
    created() {
        this.service = new Service();
    },
    mounted() {
        this.getPackList();
    },
    watch:{
        selectedPackId:function(newId,oldId){
            this.btnNextDisable=true;
            if(!newId)return;
            var errorCount=0;
            this.packList.forEach(pack=>{
                if(pack.packId==newId){
                    pack.products.forEach(product=>{
                        var isExist=this.items.some(item=>item.fnsku==product.fnsku);
                        if(!isExist){
                            pack.error=true;
                            product.error=true;
                            errorCount++;
                        }
                        
                    });
                }
                if(pack.packId==oldId){
                    pack.error=false;
                    pack.products.forEach(product=>{product.error=false});
                }
            });
            if(errorCount==0)
            this.btnNextDisable=false;
        },
    },
    methods: {
        getPackList(){
            var url='shipment/getPackList.php';
            this.service.sendHttp(url).then(res => res.json()).then(data => {
                if(data.result=='success'){
                    data.packList.forEach(pack=>{
                        var packContent=JSON.parse(pack.packContent);
                        this.translate={...this.translate,...packContent.translate};
                        pack.products=packContent.products;
                        pack.boxs=packContent.boxs;
                        pack.boxSizeWeight=packContent.boxSizeWeight;
                        pack.packContent='';

                    });
                    this.packList=data.packList;
                }
            });
        },
        createShipmentPlan(){
            var pack=this.packList.filter(pack=>pack.packId==this.selectedPackId)[0];
            pack.products.forEach(product=>{
                var itemarr=this.items.filter(item=>item.fnsku==product.fnsku);
                product.asin=itemarr[0].asin;
                product.sku=itemarr[0].sku;
            });
             var postItems=pack.products.map(item=>{return {asin:item.asin,sku:item.sku,qty:item.qty}});
             //console.log(pack.boxs);
             this.$emit('createShipmentPlan', {postItems:postItems,boxs:pack.boxs,updateData:{selectedPack:{packId:pack.packId}}});
        },
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>