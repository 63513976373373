<template>
    <div class="card">
        <div class="p-dialog-mask p-component-overlay p-component-overlay-enter" v-show="showModal" style="z-index: 100001;">
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
        </div>
        <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
            <div>
                选择店铺：<Dropdown style="width:auto" :disabled="disableSelectStore" v-model="selectedStore" :options="stores" optionLabel="storeName" optionValue="sellerId" placeholder="选择店铺"/>
                <Button class="ml-2" type="button" :label="isconfirmStoreAndAddress?'重新选择':'确定'" @click="confirmStoreAndAddress" />
                发货地址：<Dropdown  :disabled="disableSelectAddress" v-model="selectedAddress" :options="address" optionLabel="label" optionValue="id" />
            </div>
            <div>
            </div>
        </div>

        <Accordion :lazy="true" expandIcon="no" collapseIcon="no"  class="accordion-custom" :activeIndex="activeStep">
            <AccordionTab :disabled="true">
                <template #header>
                    <span>创建货件</span>
                </template>
                <TabView v-model:activeIndex="activeIndex" :lazy="true">
                <TabPanel header="商品模式">
                    <selectItems :items="items" @create-shipment-plan="createShipmentPlan"></selectItems>
                </TabPanel>
                <TabPanel header="装箱单模式">
                    <selectPack :items="items" @create-shipment-plan="createShipmentPlan"></selectPack>
                 </TabPanel>
                <TabPanel header="箱子模式">
                    <selectBox :items="items" @create-shipment-plan="createShipmentPlan"></selectBox>
                </TabPanel>
            </TabView>
                <Dialog header="预览货件" v-model:visible="dialog.shipmentList" :style="{width: '50vw'}" :modal="true">
                       <DataTable class="p-datatable-sm" :value="shipments" v-model:expanded-rows="expandedRows" dataKey="id" responsiveLayout="scroll">
                           <Column :expander="true" headerStyle="width: 3rem" />
                           <Column field="shipmentName" header="货件名称"></Column>
                           <Column field="totalQty" style="text-align:right;padding-right:2rem"></Column>
                           <Column header="操作">
                            <template #body="{data}">
                                <Button :disabled="(data.isCreated?true:false)||shipmentError" :label="data.isCreated?'已创建':'确认创建'" @click="createShipment(data.shipmentId)" />
                            </template>
                           </Column>
                           <template #expansion="{data}">
                               <div class="grid col-12" style="border: solid #dee2e6;border-width: 0 0 1px 0;" v-for="(item, index) in data.items" :key="index">
                                   <div class="col-6" style="text-align: left;padding-left:3rem">{{item.sku}}</div>
                                   <div class="col-6" style="text-align: right">{{item.qty}}</div>
                               </div>
                           </template>
                       </DataTable>
                       <Message v-show="shipmentError" severity="error">单个装箱单不能对应分仓货件</Message>
                   <template #footer>
                       <Button :disabled="shipmentError" label="下一步" @click="turnTo(2)" />
                       <Button label="取消" class="p-button-secondary" @click="()=>{this.dialog.shipmentList=false}"/>
                   </template>
               </Dialog>
            </AccordionTab>
            <AccordionTab :disabled="true">
                <template #header>
                    <span>装箱与运输</span>
                </template>
                <DataTable :value="shipments" responsiveLayout="scroll">
                    <Column field="shipmentName" header="货件名称"></Column>
                    <Column field="shipmentId" header="货件编号"></Column>
                    <Column field="totalQty" header="商品数量"></Column>
                    <Column header="箱子数量">
                        <template #body="{data}">
                            <InputText type="text" v-model="data.boxCount" :disabled="data.isConfirmBoxCount||data.usePack" style="width:70px;"/>
                            <Button type="button" :label="data.disabledConfirmBoxCountBtn?'已确认':'确认'" :loading="data.ConfirmBoxCountLoading" :disabled="data.disabledConfirmBoxCountBtn" class="ml-2" @click="putTransportDetails(data.shipmentId)" />
                        </template>
                    </Column>
                    <Column header="装箱模板">
                        <template #body="{data}">
                            <Button type="button" icon="pi pi-download" label="下载装箱模板" v-show="data.showDownloadExcelBtn" @click="getExcel(data.shipmentId)"/>
                        </template>
                    </Column>
                    <Column header="上传装箱单">
                        <template #body="{data}">
                            <div class="grid" style="align-items: center;margin-top:0;">
                                <FileUpload v-show="!data.usePack" name="excel[]" mode="basic" @select="(event)=>{data.files=event.files;}" chooseIcon="pi pi-times"  uploadIcon="pi pi-plus" :customUpload="true"  chooseLabel="选择文件" :showCancelButton="false"/>
                                <Button class="ml-2" :loading="data.uploadLoading" :disabled="data.isUpload||!data.disabledConfirmBoxCountBtn" type="button" @click="uploadExcel(data.shipmentId)"  :label="data.isUpload?'已上传':'上传'" />
                                <span class="ml-2" v-show="data.second?true:false" style="color:green">{{data.second}}</span><span class="ml-2" v-show="data.statusLabel?true:false">{{data.statusLabel}}</span>
                                <Tag v-show="data.feedStatus" class="ml-2" icon="pi pi-check" severity="success" value="已完成" rounded></Tag>
                                <Button icon="pi pi-refresh" :loading="data.getFeedStatusLoading" v-show="data.feedError" class="p-button-rounded ml-2 p-button-text" @click="getFeedStatus()" />
                            </div>
                        </template>
                    </Column>
                    <template #footer>
                        <Button type="button" :disabled="btnNextDisable"  label="下一步" @click="turnTo(3)" />
                    </template>
                </DataTable>
            </AccordionTab>
            <AccordionTab :disabled="true">
                <template #header>
                    <span>打印箱子标签</span>
                </template>
                <DataTable :value="shipments" responsiveLayout="scroll">
                    <Column field="shipmentName" header="货件名称"></Column>
                    <Column field="shipmentId" header="货件编号"></Column>
                    <Column header="箱子标签">
                        <template #body="{data}">
                            <Button type="button" :loading="data.getLabelLoading" :disabled="!data.feedStatus" :label="data.labelUrl?'下载箱子标签':'生成箱子标签'" @click="getLabel(data.shipmentId)"/>
                        </template>
                    </Column>
                    <Column  header="操作">
                        <template #body="{data}">
                            <Button type="button" :loading="data.updateShipmentLoading" :disabled="data.isUpdate" :label="data.isUpdate?'已标记为完成':'标记为已发货'" @click="updateShipment(data.shipmentId)"/>
                        </template>
                    </Column>
                </DataTable>
            </AccordionTab>
        </Accordion>
    </div>

</template>

<script>
import Service from '../../service/Service';
import selectItems from './subComponents/selectItems.vue';
import selectPack from './subComponents/selectPack.vue';
import selectBox from './subComponents/selectBox.vue';

export default {
    data() {
        return {
            shipmentError:false,//单个装箱单被分仓提示错误
            usePack:false,
            boxs:[],
            updateData:null,//修改装箱单列表或者箱子列表的数据
            activeIndex:null,
            isconfirmStoreAndAddress:false,
            disableSelectStore:false,
            disableSelectAddress:false,
            showModal:false,
            activeStep:null,
            selectedAddress:null,
            selectedStore:null,
            expandedRows:null,
            btnNextDisable:true,
            dialog:{
                shipmentList:false
            },
            address:[],
            stores: [],
            items:[],
            shipments:[],
        }
    },
    created() {
        this.service = new Service();
    },
    mounted() {
        this.stores=JSON.parse(localStorage.getItem('stores'));
    },
    watch:{
        selectedStore:function(){
            if(this.selectedStore==null)
            return;
            this.totalItem=0;
            this.totalSku=0;
            this.items=[];
            this.address=[];
            var url='shipment/getaddresslist.php?sellerid='+this.selectedStore;
            this.service.sendHttp(url).then(res=>res.json()).then(data => {
                data.forEach(address=>{
                    var newaddress={
                        name:address.name,
                        addressLine1:address.addressLine1,
                        addressLine2:address.addressLine2,
                        districtOrCounty:address.districtOrCounty,
                        city:address.city,
                        stateOrProvinceCode:address.stateOrProvinceCode,
                        countryCode:address.countryCode,
                        postalCode:address.postalCode,
                        id:address.id,
                        label:address.name+' '+address.addressLine1+' '+address.addressLine2+' '+address.city+' '+address.districtOrCounty+' '+address.stateOrProvinceCode+' '+address.countryCode+' '+address.postalCode
                    };
                    this.address.push(newaddress);
                });
                if(this.address.length>0)
                this.selectedAddress=this.address[0].id;
                else
                this.selectedAddress=null;
                
            });
            
        },
        items:function(){
            this.items.forEach(item=>{
                if(item.isConfirm){
                    this.totalItem+=item.qty;
                }
            });
        },
        activeIndex:function(){
            if(this.activeIndex==0){
                this.usePack=false;
            }
            else{
                this.usePack=true;
            }
        }
    },
    methods: {
        checkShipmentsFeedStatus(){
            var result=true;
            this.shipments.forEach(shipment=>{
                if(!shipment.feedStatus)
                result=false;
            });
            return result;
        },
        confirmStoreAndAddress(){
            if(!this.isconfirmStoreAndAddress){
                this.isconfirmStoreAndAddress=true;
                this.disableSelectAddress=true;
                this.disableSelectStore=true;
                if(this.selectedAddress!=null&&this.selectedStore!=null){
                    this.activeStep=0;
                    var url='shipment/getitemlist.php?sellerid='+this.selectedStore;
                    this.service.sendHttp(url).then(res=>res.json()).then(data => {
                        data.forEach(item=>{
                            var newitem={
                                asin:item.asin,
                                sku:item.sku,
                                fnsku:item.fnsku,
                                smallImage:item.smallImage,
                                qty:'0',
                                isConfirm:false,
                                btnLabel:'确认'
                            };
                            this.items.push(newitem);
                        });
                    });
                }
            }
            else{
                this.activeStep=null;
                this.isconfirmStoreAndAddress=false;
                this.disableSelectAddress=false;
                this.disableSelectStore=false;
                this.selectedAddress=null;
                this.selectedStore=null;
                this.items=[];
                this.shipments=[];
            }
        },
        getLabel(shipmentId){
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    if(!shipment.labelUrl){
                        shipment.getLabelLoading=true;
                        const formData = new FormData();
                        formData.append('shipmentId', shipment.shipmentId);
                        formData.append('sellerId', this.selectedStore);
                        formData.append('boxCount', shipment.boxCount);
                        var url='shipment/FbaInboundApi/getLabel.php';
                        this.service.sendHttp(url,'POST',formData).then(res => res.json()).then(data => {
                            shipment.getLabelLoading=false;
                            if(data.result=='success'){
                                shipment.labelUrl=data.url;
                                
                            }
                        });
                    }else{
                        window.open(shipment.labelUrl);
                    }
                }
            });
        },
        getFeedStatus(shipmentId){
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    shipment.getFeedStatusLoading=true;
                    clearInterval(shipment.intervalId);
                    shipment.second=null;
                    shipment.statusLabel='正在更新状态...';
                    const formData = new FormData();
                    formData.append('feedId', shipment.feedId);
                    formData.append('sellerId', this.selectedStore);
                    var url='shipment/FbaInboundApi/getFeed.php';
                    this.service.sendHttp(url,'POST',formData).then(res => res.json()).then(data => {
                        shipment.getFeedStatusLoading=false;
                                if(data.result=='success'){
                                    if(!data.reportData.includes('error')){
                                        shipment.feedStatus=true;
                                        shipment.statusLabel=null;
                                        shipment.isUpdate=false;
                                        if(this.checkShipmentsFeedStatus)
                                        this.btnNextDisable=false;
                                    }
                                    else{
                                        shipment.statusLabel="表格有错误，请重新上传";
                                        shipment.isUpload=false;
                                    }
                                    
                                }
                                else if(data.result=='getFeedDocumentError'){
                                    shipment.feedError=true;
                                    shipment.statusLabel='获取状态错误,请重试！';
                                }
                                else if(data.result=='getFeedError'){
                                    shipment.feedError=true;
                                    shipment.statusLabel='获取状态错误,请重试！';
                                }
                                else{
                                    shipment.second=10;
                                    shipment.statusLabel='秒后更新状态！';
                                    setTimeout(this.getFeedStatus,10000,shipmentId);
                                    var intervalId=setInterval(this.timer,1000,shipmentId);
                                    shipment.intervalId=intervalId;
                                }
                              });
                }

            });
        },
        uploadExcel(shipmentId){
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    shipment.statusLabel='正在上传文件...';
                    shipment.uploadLoading=true;
                    const formData = new FormData();
                    if(shipment.usePack){
                        shipment.items.forEach(item=>{
                            this.items.forEach(item1=>{
                                if(item1.sku==item.sku){
                                    item.asin=item1.asin;
                                }
                            });
                        });
                        var data={
                            shipmentId:shipment.shipmentId,
                            shipmentName:shipment.shipmentName,
                            boxCount:shipment.boxCount,
                            centerId:shipment.centerId,
                            items:shipment.items,
                            boxs:this.boxs
                        };
                        formData.append('shipmentData', JSON.stringify(data));
                    }
                    else{
                        formData.append('excelFile', shipment.files[0]);
                    }
                    
                    formData.append('sellerId', this.selectedStore);
                    var url='shipment/FbaInboundApi/uploadExcel.php';
                    this.service.sendHttp(url,'POST',formData).then(res => res.json()).then(data => {
                        //console.log(data);
                                shipment.uploadLoading=false;
                                if(data.result=='success'){
                                    shipment.isUpload=true;
                                    shipment.feedId=data.feedId;
                                    shipment.second=40;
                                    shipment.statusLabel='秒后更新状态！';
                                    setTimeout(this.getFeedStatus,40000,shipmentId);
                                    var intervalId=setInterval(this.timer,1000,shipmentId);
                                    shipment.intervalId=intervalId;
                                }
                                else{
                                    alert('上传失败,请重试！');
                                    shipment.statusLabel='上传失败,请重试！';
                                }
                              });
                }

            });
        },
        turnTo(step){
            switch(step){
                case 2:
                    var notReady=this.shipments.some(shipment=>shipment.isCreated!=true);
                    if(notReady){
                        alert('请创建全部货件!');
                        return;
                    }
                    if(!this.usePack){
                        this.dialog.shipmentList=false;
                        this.activeStep=1;
                    }
                    if(this.updateData.selectedPack){
                        this.updateData.selectedPack.shipmentId=this.shipments[0].shipmentId;
                    }
                    //这里更改装箱单或者箱子数量
                    var formData = new FormData();
                    formData.append('data', JSON.stringify(this.updateData));
                    var url='shipment/updateBoxListOrPackList.php';
                    this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                        if(data.result=='success'){
                            var message=JSON.stringify(data.message);
                            if(message.indexOf('error')<0){
                                this.dialog.shipmentList=false;
                                this.activeStep=1;
                            }
                            else{
                                if(this.updateData.selectedBoxs){
                                    var boxIds=data.message.filter(box=>{box.code=='error'}).map(box=>{return box.boxId});
                                    boxIds.forEach(boxId=>{
                                        this.updateData.selectedBoxs=this.updateData.selectedBoxs.filter(box=>box.boxId!=boxId);
                                    });
                                }
                                alert('保存失败，请重试!');
                            }
                        }
                    });
                    break;
                case 3:
                    var feedNotReady=this.shipments.some(shipment=>shipment.feedStatus!=true);
                    if(feedNotReady){
                        alert('请上传所有货件的装箱单！');
                        return;
                    }
                    this.activeStep=2;
                    break;
                default:alert('');

            }
        },
        createShipmentPlan(obj){
            this.shipments=[];
            this.showModal=true;
            var postAddress=this.address.filter(subAddress=>subAddress.id==this.selectedAddress)[0];
            var jsonobj={
                sellerId:this.selectedStore,
                address:postAddress,
                items:obj.postItems,
            };
            //console.log(jsonobj);
            var url='shipment/FbaInboundApi/createInboundShipmentPlan.php';
            this.service.sendHttp(url,'POST',JSON.stringify(jsonobj),true).then(res=>res.json()).then(data => {
                this.showModal=false;
                if(data.result=='success'){
                    this.shipments=data.shipments;
                    var d=new Date();
                    var n=d.toLocaleString();
                    this.shipments.forEach(shipment=>{
                        shipment.shipmentName="FBA API ("+n+")-"+shipment.centerId;
                        var totalQty=0;
                        shipment.items.forEach(item=>{
                            totalQty+=item.qty
                        });
                        shipment.totalQty=totalQty;
                        
                    });
                    this.shipmentError=false;
                    this.dialog.shipmentList=true;
                    if(this.shipments.length>1&&this.usePack){
                        this.shipmentError=true;
                    }
                    this.boxs=JSON.parse(JSON.stringify(obj.boxs));
                    this.updateData=obj.updateData;
                    //console.log(this.boxs);
                }else{
                    alert('创建失败,请重试！');
                }
            });
            
        },
        
        createShipment(shipmentId){
            this.showModal=true;
            var postAddress=this.address.filter(subAddress=>subAddress.id==this.selectedAddress)[0];
            var postShipment=null;
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    postShipment=shipment;
                }
            });
            var jsonobj={
                sellerId:this.selectedStore,
                shipment:postShipment,
                address:postAddress
            };
            var url='shipment/FbaInboundApi/createInboundShipment.php';
            this.service.sendHttp(url,'POST',JSON.stringify(jsonobj),true).then(res=>res.json()).then(data => {
                this.showModal=false;
                if(data.result=='success'){
                    this.shipments.forEach(shipment=>{
                        if(shipment.shipmentId==data.shipmentId){
                            shipment.isCreated=true;
                            shipment.isConfirmBoxCount=false;
                            shipment.disabledConfirmBoxCountBtn=false;
                            shipment.isUpload=false;
                            shipment.feedStatus=false;
                            if(this.usePack){
                                shipment.usePack=true;
                                shipment.boxCount=this.boxs.length;
                            }
                            //console.log(shipment);
                        }
                    });
                }
                else{
                    alert('创建失败,请重试！');
                }
            });
        },
        updateShipment(shipmentId){
            var postAddress=this.address.filter(subAddress=>subAddress.id==this.selectedAddress)[0];
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    var jsonobj={
                        sellerId:this.selectedStore,
                        shipment:shipment,
                        address:postAddress
                    };
                    shipment.updateShipmentLoading=true;
                    var url='shipment/FbaInboundApi/updateInboundShipment.php';
                    this.service.sendHttp(url,'POST',JSON.stringify(jsonobj),true).then(res=>res.json()).then(data => {
                        shipment.updateShipmentLoading=false;
                        if(data.result=='success'){
                            shipment.isUpdate=true;
                        }
                        else{
                            alert('确认失败！');
                        }
                    });
                }
            });
        },
        putTransportDetails(shipmentId){
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    shipment.ConfirmBoxCountLoading=true;
                    var data={
                        sellerId:this.selectedStore,
                        shipmentId:shipment.shipmentId,
                        boxCount:shipment.boxCount,
                        carrierName:'other'
                    };
                    var url='shipment/FbaInboundApi/putTransportDetails.php';
                    this.service.sendHttp(url,'POST',JSON.stringify(data),true).then(res=>res.json()).then(data => {
                        shipment.ConfirmBoxCountLoading=false;
                        if(data.result=='success'){
                            shipment.isConfirmBoxCount=true;
                            shipment.disabledConfirmBoxCountBtn=true;
                            if(!shipment.usePack){
                                shipment.showDownloadExcelBtn=true;
                            }    
                        }
                        else{
                            alert('确认失败，请重试！');
                        }
                    });
                }
            });
        },
        getExcel(shipmentId){
            var postShipment=this.shipments.filter(shipment=>shipment.shipmentId==shipmentId)[0];
            
            postShipment.items.forEach(item=>{
                this.items.forEach(item1=>{
                    if(item1.sku==item.sku){
                        item.asin=item1.asin;
                    }
                });
            });
            var data={
                shipmentId:postShipment.shipmentId,
                shipmentName:postShipment.shipmentName,
                boxCount:postShipment.boxCount,
                centerId:postShipment.centerId,
                items:postShipment.items
            };

            var url='shipment/FbaInboundApi/createExcel.php';
            this.service.sendHttp(url,'POST',JSON.stringify(data),true).then(res=>res.blob()).then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', shipmentId+'.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        timer(shipmentId){
            this.shipments.forEach(shipment=>{
                if(shipment.shipmentId==shipmentId){
                    shipment.second--;
                }
            });
        }

    },
    components: {
        'selectItems': selectItems,
        'selectPack':selectPack,
        'selectBox':selectBox,
    }
}
</script>

