<template>
    <span class="ml-3">已选择 SKU数量：{{postItems.length}}</span><span class="ml-3">商品数量：{{totalItem}}</span><span class="ml-3">箱数：{{boxs.length}}</span>
        <DataTable :value="boxList" :paginator="boxList.length>10?true:false" class="p-datatable-sm mt-3" v-model:expandedRows="expandedItems" dataKey="boxId" stripedRows  :rows="10">
            <Column :expander="true" style="max-width:3rem" />
            <Column field="boxName" header="箱子名称">
                <template #body="{data}">
                    {{ data.boxName }}
                    <Tag v-if="data.error" class="ml-2" icon="pi pi-times" severity="danger" value="此箱子有商品条码不属于本店铺"></Tag>
                </template>
            </Column>
            <Column field="boxCount" header="箱子数量"></Column>
            <Column field="productCount" header="商品总数"></Column>
            <Column style="text-align:right;">
                <template #body="{data}">
                    选择箱数：<InputNumber v-model="data.qty" inputStyle="width:60px;" :allowEmpty="false" mode="decimal" :min="0" :max="parseInt(data.boxCount)" :disabled="data.isConfirm"/>
                    <Button type="button" :label="data.btnLabel" class="ml-2" @click="confirmclick(data.boxId)" />
                </template>
            </Column>
            <template #expansion="{data}">
                <div class="card mb-0" style="width:100%">
                    <DataTable :value="data.products" class="p-datatable-sm mt-3" stripedRows  :rows="20">
                        <Column field="fnsku" header="商品信息">
                            <template #body="{data}">
                                <itemInfo  :item="{imgUrl:translate[data.fnsku].imgUrl,asin:'1',fnsku:'1',sku:'1'}">
                                    <template #asin>
                                        商品名称：{{translate[data.fnsku].productNameZh}}
                                    </template>
                                    <template #sku>
                                        ASIN：{{translate[data.fnsku].asin}}
                                    </template>
                                    <template #fnsku>
                                        FNSKU：{{data.fnsku}}
                                        <Tag v-if="data.error" class="ml-2" icon="pi pi-times" severity="danger" value="此商品条码不属于本店铺"></Tag>
                                    </template>
                                </itemInfo>
                            </template>
                        </Column>
                        <Column field="qty" header="数量"></Column>
                    </DataTable> 
                </div>
            </template>   
            <template #footer>
                <div style="justify-content:space-between;display: flex;">
                    <Button :disabled="btnNextDisable" label="下一步" @click="createShipmentPlan" />
                    <Button icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="()=>{this.getBoxList();}" />
                </div>
            </template>
        </DataTable>   
</template>

<script>
import Service from '../../../service/Service';
import itemInfo from '../../common/itemInfo.vue';

export default {
    emits:['createShipmentPlan'],
    props: {
        items:Array
    },
    data() {
        return {
            boxList:[],
            expandedItems:[],
            btnNextDisable:true,
            translate:{},
            totalItem:0,
            postItems:[],
            boxs:[],
            selectedBoxs:[]
        }
    },
    created() {
        this.service = new Service();
    },
    mounted() {
        this.getBoxList();
    },
    watch:{
        totalItem:function(){
            if(this.totalItem>0){
                this.btnNextDisable=false;
            }
            else{
                this.btnNextDisable=true;
            }
        },
    },
    methods: {
        getBoxList(){
            this.boxList=[];
            var url='shipment/getBoxList.php';
            this.service.sendHttp(url).then(res => res.json()).then(data => {
                if(data.result=='success'){
                    data.boxList.forEach(box=>{
                        var content=JSON.parse(box.content);
                        this.translate={...this.translate,...content.translate};
                        box.products=content.boxContent.products;
                        box.boxSizeWeight=content.boxContent.boxSizeWeight;
                        box.content='';
                        box.qty=0;
                        box.btnLabel='确认';
                        box.isConfirm=false;
                        box.productCount=box.products.map(item=>{return item.qty}).reduce((total,num)=>{return total+num});
                        box.productCount*=box.boxCount;
                    });
                    this.boxList=data.boxList;
                    this.calculateTotal();
                }
            });
        },
        confirmclick(boxId) {
            var errorCount=0;
            this.boxList.forEach(box=>{
                if(box.boxId==boxId){
                    if(box.isConfirm){
                        box.isConfirm=false;
                        box.btnLabel='确认';
                        box.error=false;
                        box.products.forEach(product=>{product.error=false;});
                    }
                    else{
                        box.products.forEach(product=>{
                            var isExist=this.items.some(item=>item.fnsku==product.fnsku);
                            if(!isExist){
                                product.error=true;
                                box.error=true;
                                errorCount++;
                            }
                        });
                        if(errorCount>0)return;
                        box.isConfirm=true;
                        box.btnLabel='编辑'
                    }
                }
            });
            if(errorCount==0)
            this.calculateTotal();
        },
        calculateTotal(){
            this.totalItem=0;
            this.totalSku=0;
            this.postItems=[];
            this.boxs=[];
            this.selectedBoxs=[];
            var confirmBoxs=this.boxList.filter(box=>box.isConfirm==true);
            confirmBoxs.forEach(box=>{
                box.products.forEach(product=>{
                    var itemarr=this.items.filter(item=>item.fnsku==product.fnsku);
                    var isExist=this.postItems.some(item=>item.fnsku==product.fnsku);
                    if(!isExist){
                        var item={asin:itemarr[0].asin,sku:itemarr[0].sku,fnsku:product.fnsku,qty:parseInt(product.qty)*parseInt(box.qty)};
                        this.postItems.push(item);
                    }
                    else{
                        this.postItems.forEach(item=>{
                            if(item.fnsku==product.fnsku){
                                item.qty+=parseInt(product.qty)*parseInt(box.qty);
                            }
                        });
                    }
                });
                for(var i=0;i<box.qty;i++){
                    var postBox={boxId:this.boxs.length+1,products:box.products,boxSizeWeight:box.boxSizeWeight};
                    this.boxs.push(postBox);
                }
                this.selectedBoxs.push({boxId:box.boxId,qty:box.qty});
            });
            if(this.postItems.length==0){
                this.totalItem=0;
            }else{
                this.totalItem=this.postItems.map(item=>{return item.qty}).reduce((total,num)=>{return total+num});
            }
        },
        createShipmentPlan(){
            var postItems=this.postItems.map(item=>{return {asin:item.asin,sku:item.sku,qty:item.qty}});
            this.$emit('createShipmentPlan', {postItems:postItems,boxs:this.boxs,updateData:{selectedBoxs:this.selectedBoxs}});
        },
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>