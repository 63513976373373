<template>
    <div  style="justify-content:space-between;display: flex;">
                    <div>
                        <span class="p-input-icon-left ml-2"><i class="pi pi-search" /><InputText v-model="filters['global'].value" placeholder="搜索FNSKU、SKU、ASIN" /></span>
                        <span class="ml-3">已选择 SKU数量：{{totalSku}}</span><span class="ml-3">商品数量：{{totalItem}}</span>
                        
                    </div>
                    <div>
                        <Button type="button" label="已选商品" @click="filters['isConfirm'].value=true" />
                        <Button class="ml-2"  type="button" label="全部商品" @click="initFilters()" />
                    </div>
                </div>
                <DataTable :value="items" class="p-datatable-sm mt-3" stripedRows :paginator="true" :rows="5" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" v-model:filters="filters" :globalFilterFields="['asin','sku','fnsku']">
                    <Column field="fnsku" header="商品信息">
                        <template #body="{data}">
                            <itemInfo :item="{imgUrl:data.smallImage,asin:data.asin,sku:data.sku,fnsku:data.fnsku}" />
                        </template>
                    </Column>
                    <Column field="qty" style="text-align:right;">
                        <template #body="{data}">
                            数量：<InputText type="text" v-model="data.qty" style="width:70px;" :disabled="data.isConfirm"/><Button type="button" :label="data.btnLabel" class="ml-2" @click="confirmclick(data.fnsku)" />
                        </template>
                    </Column>
                    <template #paginatorstart>
                    <Button type="button" :disabled="btnNextDisable" label="下一步" @click="createShipmentPlan()"/>
                    </template>
                </DataTable>
</template>

<script>
import Service from '../../../service/Service';
import {FilterMatchMode} from 'primevue/api';
import itemInfo from '../../common/itemInfo.vue';

export default {
    emits:['createShipmentPlan'],
    props: {
        items:Array
    },
    data() {
        return {
            totalSku:0,
            totalItem:0,
            btnNextDisable:true,
            filters: {}
        }
    },
    created() {
        this.service = new Service();
        this.initFilters();
    },
    mounted() {
    },
    watch:{
        totalItem:function(){
            if(this.totalItem>0){
                this.btnNextDisable=false;
            }
            else{
                this.btnNextDisable=true;
            }
        },
        items:function(){
            this.items.forEach(item=>{
                if(item.isConfirm){
                    this.totalItem+=item.qty;
                }
            });
        }
    },
    methods: {
        createShipmentPlan(){
            var postItems=this.items.filter(item=>item.isConfirm==true).map(item=>{return {asin:item.asin,sku:item.sku,qty:item.qty}});
            this.$emit('createShipmentPlan', {postItems:postItems,boxs:[]});
        },
        confirmclick(fnsku) {
            this.items.forEach(item=>{
                if(item.fnsku==fnsku){
                    if(item.isConfirm){
                        item.isConfirm=false;
                        item.btnLabel='确认'
                    }
                    else{
                        item.isConfirm=true;
                        item.btnLabel='编辑'
                    }
                }
            });
            this.calculateTotal();
        },
        calculateTotal(){
            this.totalItem=0;
            this.totalSku=0;
            var confirmItems=this.items.filter(item=>item.isConfirm==true);
            confirmItems.forEach(item=>{
                this.totalItem+=parseInt(item.qty);
                this.totalSku++;
            });
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'isConfirm': {value: null, matchMode: FilterMatchMode.EQUALS}
            }
        },
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>